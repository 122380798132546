<template>
  <div class="spinner-container">
    <b-spinner
      style="width: 3rem; height: 3rem; margin: 0 auto; display: block"
      variant="primary"
    />
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import { HTTP } from "@/libs/axios";
import { parseJwt } from "@/auth/utils";

export default {
  components: {
    BSpinner,
  },
  computed: {
    ...mapState("userManagement", ["vendorToken"]),
  },
  created() {
    const { hash } = this.$route.query;
    this.impersonate(hash);
  },
  methods: {
    ...mapActions("userManagement", ["vendorSessionAuth", "getUserModules"]),
    ...mapActions("accountSettings", ["getUserInfo", "getUserApplication"]),
    async impersonate(hash) {
      localStorage.clear();
      await this.vendorSessionAuth(hash);
      const parsedToken = parseJwt(this.vendorToken);
      localStorage.setItem("userInfo", JSON.stringify(parsedToken));
      localStorage.setItem("token", this.vendorToken);
      localStorage.setItem("lang", "ka");
      HTTP.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        "token"
      )}`;

      await this.getUserModules();
      await this.getUserInfo();
      await this.getUserApplication();
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
